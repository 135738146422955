@use '@angular/material' as mat;

@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500');

$fontConfig: (display-4: mat.define-typography-level(112px, 112px, 300, 'Ubuntu', -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, 'Ubuntu', -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, 'Ubuntu', 0.0000em),
    display-1: mat.define-typography-level(34px, 40px, 400, 'Ubuntu', 0.0074em),
    headline: mat.define-typography-level(24px, 32px, 400, 'Ubuntu', 0.0000em),
    title: mat.define-typography-level(20px, 32px, 500, 'Ubuntu', 0.0075em),
    subheading-2: mat.define-typography-level(16px, 28px, 400, 'Ubuntu', 0.0094em),
    subheading-1: mat.define-typography-level(15px, 24px, 500, 'Ubuntu', 0.0067em),
    body-2: mat.define-typography-level(14px, 24px, 500, 'Ubuntu', 0.0179em),
    body-1: mat.define-typography-level(14px, 20px, 400, 'Ubuntu', 0.0179em),
    button: mat.define-typography-level(14px, 14px, 500, 'Ubuntu', 0.0893em),
    caption: mat.define-typography-level(12px, 20px, 400, 'Ubuntu', 0.0333em),
    input: mat.define-typography-level(inherit, 1.125, 400, 'Ubuntu', 1.5px));


// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-legacy-component-typographies($fontConfig);
@include mat.legacy-core();


/* 
Specific component overrides, pieces that are not in line with the general theming
Handle buttons appropriately, with respect to line-height 
*/


.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
        font-family: 'Ubuntu';
    }
}


/* Font class over boys */